<template>
  <div class="bt-anggota pa-5">
    <Header title="Wakaf Anggota" titleIcon="mdi-wallet" />

    <div class="mt-5">
      <v-select
        solo
        label="Rembug"
        class="mb-4"
        hide-details
        :items="rembug"
        item-text="cm_name"
        item-value="cm_code"
        v-model="cm_code"
        readonly
      />

      <v-select
        solo
        label="Kelompok"
        class="mb-4"
        hide-details
        :items="kelompok"
        item-text="nama_kelompok"
        item-value="kode_kelompok"
        v-model="kode_kelompok"
        readonly
      />

      <v-menu
        ref="menu"
        v-model="dateShow"
        :close-on-content-click="false"
        :return-value.sync="transaction_date"
        transition="scale-transition"
        offset-y
        min-width="auto"
        class="white"
        disabled
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            solo
            v-model="transaction_date"
            label="Tanggal"
            readonly
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker v-model="transaction_date" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="dateShow = false"> Cancel </v-btn>
          <v-btn text color="primary"> OK </v-btn>
        </v-date-picker>
      </v-menu>

      <v-card class="white elevation-3 rounded-lg pa-3 align-items-end mb-3">
        <div class="d-flex justify-space-between">
          <span><b>Total Setoran</b></span>
          <h5>Rp {{ thousand(total_setoran) }}</h5>
        </div>
      </v-card>

      <v-card
        class="white rounded-lg mb-3 pt-3"
        v-if="setoran && setoran.length == 0"
      >
        <v-row no-gutters>
          <v-col class="text-center"> Loading... </v-col>
        </v-row>
      </v-card>

      <v-card class="white rounded-lg mb-3 pt-3" v-else>
        <div v-for="(item, index) in setoran" :key="index">
          <div class="pl-3">
            <v-row class="ml-0 mt-0">
              <v-col cols="6">
                <label class="font-weight-bold">{{ item.nama }}</label>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  v-model="item.setoran"
                  v-mask="thousandMask"
                  min="1"
                  :rules="[
                    () => !!item.setoran || 'This field is required'
                  ]"
                  dense
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
          <v-divider class="mb-5"></v-divider>
        </div>
      </v-card>

      <v-row>
        <v-col cols="6" class="pb-0">
          <router-link :to="`/others/wakaf/kelompok/${cm_code}/${branch_id}/${transaction_date}`">
            <v-btn block class="purple lighten-1 white--text"> Kembali </v-btn>
          </router-link>
        </v-col>
        <v-col cols="6" class="pb-0">
          <v-btn block class="purple lighten-1 white--text" @click="doSave()">
            Simpan
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <v-snackbar v-model="alert.show" :timeout="5000">
      {{ alert.msg }}
    </v-snackbar>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import helper from "@/utils/helper";
import Toast from "@/components/Toast";
import Header from "@/components/Header.vue";
import { mapGetters, mapActions } from "vuex";
import services from "@/services";
export default {
  name: "WakafAnggota",
  components: {
    Toast,
    Header,
  },
  data() {
    return {
      overlay: false,
      rembug: [],
      kelompok: [],
      setoran: [],
      total_setoran: 0,
      cm_code: null,
      kode_kelompok: null,
      branch_id: null,
      transaction_date: null,
      dateShow: false,
      alert: {
        show: false,
        msg: "",
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  watch: {
    setoran: {
      /**
       * Updates the `setoran` property of each `detail` object in `setoran` array
       * based on the `setoran` and `freq` properties of each `detail` object.
       *
       * @param {Array} newVal - The new value of the `setoran` array.
       * @param {Array} oldVal - The old value of the `setoran` array.
       */
      handler: function (newVal, oldVal) {
        if (newVal) {
          let total_setoran = 0;
          this.setoran.forEach((item) => {
            const setoran = Number(
              item.setoran ? String(item.setoran).replace(/\./g, "") : 0,
            );

            total_setoran += setoran;
          });
          this.total_setoran = total_setoran;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...helper,
    async getRembug() {
      try {
        let payload = new FormData();
        payload.append("fa_code", this.user.fa_code);
        payload.append("hari_transaksi", '99');

        this.overlay = true;
        const response = await services.infoRembug(payload, this.user.token);
        if (response.status === 200) {
          if (Array.isArray(response.data.data)) {
            this.rembug = response.data.data;
          } else {
            this.alert = {
              show: true,
              msg: "Rembug tidak ditemukan",
            };
          }
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      } finally {
        this.overlay = false;
      }
    },
    async getKelompok() {
      const fa_code = this.user.fa_code || null;
      const account_cash_code = this.user.account_cash_code || null;

      let payload = new FormData();
      payload.append("cm_code", this.cm_code);
      payload.append("branch_id", this.branch_id);
      payload.append("transaction_date", this.transaction_date);
      payload.append("fa_code", fa_code);
      payload.append("account_cash_code", account_cash_code);

      try {
        this.overlay = true;
        const response = await services.infoGroup(payload, this.user.token);
        if (response.status === 200) {
          const { kelompok } = response.data.data;
          this.kelompok = kelompok;
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      } finally {
        this.overlay = false;
      }
    },
    async getAnggota() {
      let payload = new FormData();
      payload.append("cm_code", this.cm_code);
      payload.append("kode_kelompok", this.kode_kelompok);
      payload.append("transaction_date", this.transaction_date);

      try {
        this.overlay = true;
        const response = await services.infoMemberWakaf(
          payload,
          this.user.token
        );

        if (response.status === 200) {
          const { setoran = [], total_setoran = 0 } = response.data.data;

          for (let item of setoran) {
            if (item.detail) {
              for (let itemDetail of item.detail) {
                Object.assign(itemDetail, {
                  setoran_temp: itemDetail.setoran,
                });
              }
            }
          }

          this.setoran = setoran;
          this.total_setoran = total_setoran;
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error.message || error,
        };
      } finally {
        this.overlay = false;
      }
    },
    async doSave() {
      this.overlay = true;
      try {
        let payload = new FormData();

        for (let [index, item] of this.setoran.entries()) {
          const { id = null, setoran = null } = item;

          if (setoran === null || setoran === "") {
            throw new Error(`Setoran tidak boleh kosong ${index}`);
          }

          payload.append(`id[${index}]`, id);
          payload.append(`setoran_wakaf[${index}]`, setoran);
        }

        const response = await services.transactionSetorWakaf(
          payload,
          this.user.token
        );

        if (response.status == 200) {
          this.alert = {
            show: true,
            msg: response.data?.data?.msg || "Berhasil disimpan!",
          };

          setTimeout(() => {
            this.$router.push(
              `/others/wakaf/kelompok/${this.cm_code}/${this.branch_id}/${this.transaction_date}`
            );
          }, 500);
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error.message || error,
        };
      } finally {
        this.overlay = false;
      }
    },
  },
  mounted() {
    this.cm_code = this.$route.params.cm_code;
    this.branch_id = this.$route.params.branch_id;
    this.transaction_date = this.$route.params.transaction_date;
    this.kode_kelompok = parseInt(this.$route.params.kode_kelompok, 10);

    this.getRembug();
    this.getKelompok();
    this.getAnggota();
  },
};
</script>
